import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useState } from 'react';
import './App.css';

import RoutingNavbar from './components/RoutingNavbar';
import CertificatesTable from './pages/CertificatesTable';
import CertificateDetails from './pages/CertificateDetails';
import Incomes from './pages/Incomes';
import IncomeDetails from './pages/IncomeDetails';
import Account from './pages/Account';
import Login from './pages/Login';
import UserManagement from './pages/UserManagement';
import CreateUser from './pages/CreateUser';
import CreateCertificate from './pages/CreateCertificate';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { baseUrl } from './BaseUrl';

axios.defaults.withCredentials = true;

function App() {

	useEffect(() => {
		window.onstorage = (event) => {
			if (event.key !== 'isAuthenticated' && event.key !== 'isAdmin')
				return;
			
			setIsAuthenticated(JSON.parse(localStorage.getItem('isAuthenticated') || 'false'));
			setIsAdmin(JSON.parse(localStorage.getItem('isAdmin') || 'false'));
		}
	}, [])

	async function ChekRole(role) {
		const response = await axios.get(baseUrl + "/Authorization/IsInRole/" + role,
			{ withCredentials: true });

		setIsAdmin(response.data);
		localStorage.setItem('isAdmin', JSON.stringify(response.data));
	}

	const [isAuthenticated, setIsAuthenticated] = useState(JSON.parse(localStorage.getItem('isAuthenticated') || 'false'));
	const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('isAdmin') || 'false'));

	// navChildren - children of button from navbar, if null - button will not be rendered
	const elements = [
		{
			path: "/Account",
			navChildren: <div>Аккаунт</div>,
			canNavigate: () => isAuthenticated,
			fallbackPath: "/Login",
			element: <Account />,
		},
		{
			path: "/Certificates/:page",
			navChildren: <div>Сертификаты</div>,
			canNavigate: () => isAuthenticated,
			fallbackPath: "/Login",
			element: <CertificatesTable />,
		},
		{
			path: "/Certificates/Create",
			canNavigate: () => isAuthenticated,
			fallbackPath: "/Login",
			element: <CreateCertificate />,
		},
		{
			path: "/Certificate/:id",
			canNavigate: () => isAuthenticated,
			fallbackPath: "/Login",
			element: <CertificateDetails />,
		},
		{
			path: "/Incomes",
			navChildren: <div>Поступления</div>,
			canNavigate: () => isAuthenticated,
			fallbackPath: "/Login",
			element: <Incomes />,
		},
		{
			path: "/Incomes/:id",
			canNavigate: () => isAuthenticated,
			fallbackPath: "/Login",
			element: <IncomeDetails />,
		},
		{
			path: "/UserManagement",
			navChildren: <div>Пользователи</div>,
			canNavigate: () => isAdmin,
			fallbackPath: "/Login",
			element: <UserManagement />,
		},
		{
			path: "/UserManagement/Create",
			canNavigate: () => isAdmin,
			fallbackPath: "/Login",
			element: <CreateUser />,
		},
		{
			path: "/Login",
			canNavigate: () => !isAuthenticated,
			fallbackPath: "/Incomes",
			navChildren: <div>Вход</div>,
			element: <Login />,
		},
		{
			path: "/",
			canNavigate: () => false,
			fallbackPath: "/Incomes",
			element: <div/>
		}
	];

	useEffect(() => {
		const checkAuth = async () => {
			const response = await axios.get(baseUrl + "/Authorization/IsAuthorized",
				{ withCredentials: true }
			);
			setIsAuthenticated(response.data);
			localStorage.setItem('isAuthenticated', JSON.stringify(response.data));
		};
		checkAuth();
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			const checkRole = async () => {
				await ChekRole("Admin");
			};
			checkRole();
		}
	}, [isAuthenticated]);

	return (
		<div className="App">
			<Helmet>
				<title>Контроль поступлений</title>
			</Helmet>

			<RoutingNavbar elements={elements} />
		</div>
	);
}

export default App;
