import * as React from "react";
import { BrowserRouter, Navigate, useNavigate } from "react-router-dom";
import { Routes, Route, RouteProps } from "react-router-dom";
import { Button, Navbar, Nav } from "react-bootstrap";
import logo from '../art/logo1024.png';

export interface RoutingElement {
    path: string,
    navChildren?: JSX.Element,
    canNavigate?: () => boolean,
    fallbackPath?: string,
    element: JSX.Element
}

interface NavbarProps {
    children?: JSX.Element,
    elements: RoutingElement[],
    defaultRoutes?: RouteProps[]
}

export default function RoutingNavbar(props: NavbarProps) {
    const elements = props.elements;

    return (
        <BrowserRouter >
            <Navbar className="navv" collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Toggle className="navbar-toggler-collapsed" aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" style={{ backgroundColor: "#212529" }}>
                    <img src={logo} height={40} alt="logo" style={{ marginLeft: '10px', marginRight: '10px', alignSelf: 'center' }}/>
                    <Nav className="me-auto">
                        {elements.map((param) => param.navChildren == null ? null : (
                            <PrivateNavButton
                                path={param.path}
                                children={param.navChildren}
                                canNavigate={param.canNavigate}
                                fallbackPath={param.fallbackPath} />
                        ))}
                    </Nav>
                    
                </Navbar.Collapse>
                
            </Navbar>
            
            <Routes>
                {elements.map((param) => (
                    <Route
                        path={param.path}
                        element={
                            param.canNavigate == null || param.canNavigate() ? param.element :
                                (param.fallbackPath != null ? <Navigate to={param.fallbackPath} replace={true} /> : null)
                        } />
                ))}
            </Routes>
        </BrowserRouter>
    )
}

export interface NavButProps {
    path : string,
    children?: JSX.Element,
    canNavigate?: () => boolean,
    fallbackPath?: string
}

function PrivateNavButton(props : NavButProps) {
    const { path, children, canNavigate } = props

    const navigate = useNavigate();
    
    if (canNavigate != null && !canNavigate())
    {
        return null;
    }

    return (
        <Nav.Link className="p-0">
            <Button onClick={() => navigate(path)} className="nav-b">
                {children}
            </Button>
        </Nav.Link>
    )
}