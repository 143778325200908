export function GetBaseUrl() : string {
    let url: string;
    switch (process.env.NODE_ENV) {
        case 'production':
            url = 'https://rest.incomes.sps.qsolution.ru'; // Replace with our production URL
            break;
        case 'development':
        default:
            url = 'http://localhost:8109';
    }

    return url;
}

const baseUrl : string = GetBaseUrl();
export { baseUrl }