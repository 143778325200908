import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { ListGroup } from 'react-bootstrap';
import { Table } from "antd";
import { Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { Income } from '../types/Income';
import { baseUrl } from '../BaseUrl';

export default function Incomes() {
    const navigate = useNavigate();

    const [incomes, setIncomes] = useState<Income[]>();

    const fetchIncomes = async () => {
        const response = await axios.get(baseUrl + "/api/Incomes",
            { withCredentials: true }
        );
        setIncomes(response.data)
    };

    useEffect(() => {
        fetchIncomes();
    }, []);

    const columns: ColumnsType<Income> = [
        {
            dataIndex: 'incomeDate',
            key: 'incomeDate',
            title: 'Дата поступления',
            width: "25%",
            render(value) {
                return new Date(value).toLocaleDateString();
            },
        },
        {
            width: "90%",
            dataIndex: 'description',
            key: 'description',
            title: 'Описание',
        },
        {
            key: 'details',
            render: (_, record : Income) => (
                <Button onClick={() => navigate("/Incomes/" + record.id)}>Подробнее</Button>
            )
        }
    ]

    return (
        <div className='page-container'>
            <h3 style={{ textAlign: "center", margin: "20px" }}>Поступления</h3>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Box sx={{ height: "100%", width: '100%' }}>
                    <Table style={{ margin: "20px" }}
                        dataSource={incomes?.map((income, index) => ({ ...income, key: index }))}
                        columns={columns}
                        bordered
                        expandable={{
                            expandedRowRender: (income) => (
                                <ListGroup.Item>
                                    {income.items == null || income.items.length === 0 || income.items[0].photoUrls.length === 0 ? null :
                                        <div>
                                            <div className="fw-bold">Поступившие предметы</div>
                                            <div>
                                                {income.items.slice(0, 5).map((item, index) => item.photoUrls[0] == null ? null : (
                                                    <Tooltip placement='bottom' title={item.name + " (" + item.quantity + " штук)"}>
                                                        <img src={item.photoUrls[0]} alt="preview" key={index} width={100} height={100} style={{ margin: "5px" }} />
                                                    </Tooltip>
                                                ))}
                                                {
                                                    income.items.length > 5 ? <div>... и еще {income.items.length - 5} предметов</div> : null
                                                }
                                            </div>
                                        </div>}
                                </ListGroup.Item>
                            ),
                            rowExpandable: (record) => record.items != null && record.items.length > 0 && record.items[0].photoUrls.length > 0
                        }}>
                    </Table>
                </Box>
            </div>
        </div>
    )
}