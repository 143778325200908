import React, { useEffect, useState, useRef, Key } from 'react';
import Highlighter from 'react-highlight-words';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, InputRef, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { Alert } from 'react-bootstrap';

import { baseUrl } from '../BaseUrl';
import { CertificateDto } from '../types/Certificate';

const CertificatesTable = () => {
    const { page } = useParams();
    const [certificates, setCertificates] = useState([]);
    const [isErrorLinked, setIsErrorLinked] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const fetchCertificates = async () => {
        const response = await axios.get(baseUrl + "/api/Certificates",
            { withCredentials: true }
        );
        setCertificates(response.data)
    };

    useEffect(() => {
        fetchCertificates();
    }, []);

    const handleDelete = async (id: string) => {
        await axios.delete(baseUrl + "/api/Certificates/Delete/" + id,
            { withCredentials: true }

        ).then((resp: AxiosResponse) => {

            if (resp.status === 200)
                fetchCertificates();
            setErrorMessage(resp.data);

        }).catch((error: AxiosError) => {

            if (error.response && error.response.data) {
                setErrorMessage(error.response.data as string);
                setIsErrorLinked(true);
            }
        })
        fetchCertificates();
    };


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: () => void) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Поиск
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Сброс
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: boolean | Key, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select());
            }
        },
        render: (text: string, record: CertificateDto) =>
            searchedColumn === dataIndex ? (
                <Button type='link' onClick={() => navigate("/Certificate/" + record.id)} style={{ maxWidth: "100%", height: "10000%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "break-spaces", textAlign: "left" }}>
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                </Button>
            ) : (
                <Button type='link' onClick={() => navigate("/Certificate/" + record.id)} style={{ maxWidth: "100%", height: "10000%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "break-spaces", textAlign: "left" }}>{text}</Button>
            ),
    });


    const columns: ColumnsType<CertificateDto> = [
        {
            dataIndex: 'name',
            title: 'Название сертификата',
            width: "100%",
            ...getColumnSearchProps('name')
        },
        {
            render: (_, record: CertificateDto) => (
                <Button danger onClick={() => handleDelete(record.id)}>Удалить</Button>
            ),
        }

    ];


    return (
        <div className='page-container'>
            <h3 style={{ textAlign: "center", margin: "20px" }}>Сертификаты</h3>
            <div style={{ display: "flex", alignItems: "center", margin: "20px", justifyContent: "center" }}>
                <Box sx={{ height: "100%", width: '100%' }}>
                    <Table
                        dataSource={certificates}
                        columns={columns}
                        pagination={{
                            defaultCurrent: Number(page) || 1,
                            onChange(pageNum, _) {
                                navigate("/Certificates/" + pageNum);
                            },
                        }}
                    />
                    <Button type='primary' onClick={() => navigate("/Certificates/Create")} style={{ marginTop: "20px" }}>
                        Создать сертификат
                    </Button>
                </Box>
            </div>

            <div style={{ position: "absolute", left: "50%", top: "50%", minWidth: "20%", transform: "translate(-50%, -50%)" }}>
                <Alert show={isErrorLinked} variant="danger">
                    <Alert.Heading>Ошибка</Alert.Heading>
                    <p>
                        {errorMessage}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setIsErrorLinked(false)} type="primary">
                            Закрыть
                        </Button>
                    </div>
                </Alert>
            </div>
        </div>
    );
};

export default CertificatesTable;