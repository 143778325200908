import React, { useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { baseUrl } from '../BaseUrl';
import logo from '../art/logo.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseUrl + '/Authorization/SignIn', {
                UserName: username,
                Password: password
            }, { withCredentials: true });

            if (response.data.succeeded) {
                setMessage('Авторизация успешна!');
                window.location.reload();
            } else {
                setMessage('Ошибка авторизации: Неверное имя пользователя или пароль.');
            }
        } catch (error) {
            setMessage(error.response ? error.response.data : 'Ошибка сети');
        }
    };

    return (
        <div className='page-container'>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '400px', width: '100%', margin: 'auto' }}>
                <img src={logo} alt="logo"/>
                <Form.Label style={{ marginTop: '15px', fontSize: '24px', fontStyle: 'bold' }}>Авторизация</Form.Label>
                <Form onSubmit={handleLogin}>
                    <Form.Group style={{ marginTop: '10px' }}>
                        <Form.Control
                            type="text"
                            placeholder="Имя пользователя"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group style={{ marginTop: '10px' }}>
                        <Form.Control
                            type="password"
                            placeholder="Пароль"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button style={{ marginTop: '10px' }} type="submit">Войти</Button>
                </Form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default Login;
