import React, { useEffect } from "react";
import axios from 'axios';
import { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";
import { Select } from "antd";

import { CertificateData } from "../types/Certificate";
import { SizeInfo } from "../types/SizeInfo";
import { baseUrl } from "../BaseUrl";
import { Helmet } from "react-helmet";

function CreateCertificate() {

    const [certificate, setCertificate] = useState<CertificateData>({ name: '', files: [] });
    const [message, setMessage] = useState('');
    const [sizes, setSizes] = useState<SizeInfo[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        axios.get(baseUrl + '/api/Certificates/GetSizes', { withCredentials: true }).then((response) => {
            setSizes(response.data);
        });
    }, []);

    const [validated, setValidated] = useState(false)
    const [success, setSuccess] = useState(false)
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', certificate.name);
        formData.append('sizeTypeId', certificate.sizeTypeId === undefined ? '' : certificate?.sizeTypeId as string);
        if (certificate.files) {
            for (let i = 0; i < certificate.files.length; i++) {
                formData.append('files', certificate.files[i]);
            }
        }

        try {
            const response = await axios.post(baseUrl + '/api/Certificates', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });

            setMessage('Сертификат успешно загружен: ' + response.data);
            setSuccess(true);
            setValidated(true);
        } catch (error: any) {
            setValidated(false);
            setMessage('Ошибка загрузки сертификата: ' + (error.response ? error.response.data : 'Ошибка сети'));
        }
    };

    function setSelectedFiles(files: File[]) {
        setCertificate({ ...certificate, files: files });
    }

    const handleFileChange = (event :  React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles([...certificate.files, ...Array.from(event.target.files as FileList) as File[]]);
    };

    const handleFileRemove = (index : number) => {
        const newFiles = [...certificate.files];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles as File[]);
    };

    const endCreation = () => {
        setSuccess(false);
        navigate('/Certificates');
    }

    return (
        <div className="page-container">
            <Helmet>
                <title>Создание сертификата</title>
            </Helmet>
            <h3 style={{ textAlign: "center", margin: "20px" }}>Создание сертификата</h3>

            <Form validated={validated} onSubmit={handleSubmit} style={{ maxWidth: '500px', alignContent: 'center', margin: 'auto', marginTop: '50px' }}>
                <Form.Group className="mb-3" >
                    <Form.Label>Название сертификата</Form.Label>
                    <Form.Control required type="text" value={certificate.name} onChange={(event) => setCertificate({ ...certificate, name: event.target.value })} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="m-3">Размер</Form.Label>
                    <Select showSearch optionFilterProp="label" style={{ width: "100%" }} onChange={(value) => setCertificate({ ...certificate, sizeTypeId: value })} placeholder="Выберите размер"
                        options={sizes.map((size) => ({ value: size.id, label: size.name }))}/>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Приложения</Form.Label>
                    <Form.Control type="file" multiple onChange={handleFileChange} />
                    <ListGroup>
                        {certificate.files.map((file, index) => {
                            if (typeof file === 'string') {
                                return <ListGroup.Item key={index}>{file}</ListGroup.Item>;
                            }
                            return (
                                <ListGroup.Item key={index}>
                                    {file.name}
                                    <Button style={{ marginLeft: "20px" }} variant="secondary" onClick={() => handleFileRemove(index)}>Удалить</Button>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Создать
                </Button>
                {message && <p>{message}</p>}
            </Form>

            <div style={{ position: "absolute", right: "40%", top: "35%", minWidth: "20%" }}>
                <Alert show={success} variant="success">
                    <Alert.Heading>Успешно</Alert.Heading>
                    <p>
                        Сертификат добавлен
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={endCreation} variant="outline-success">
                            Закрыть
                        </Button>
                    </div>
                </Alert>
            </div>
        </div>


    );
}

export default CreateCertificate;