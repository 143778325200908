import React, { useEffect } from "react";
import axios from 'axios';
import { Button } from "react-bootstrap";

import { User } from "../types/User";
import { baseUrl } from "../BaseUrl";

function Account() {
    const [user, setUser] = React.useState<User>();

    const getUser = async () => {
        const response = await axios.get(baseUrl + "/UserManagement/LocalUser", { withCredentials: true });
        setUser(response.data);
    };

    useEffect(() => {
        getUser();
    }, []);

    const logout = async () => {
        await axios.post(baseUrl + '/Authorization/SignOut', { withCredentials: true });
        localStorage.setItem('isAuthenticated', 'false');
        localStorage.setItem('isAdmin', 'false');
        window.location.reload();
    }

    return (
        <div className="page-container">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '400px', marginTop: '100px', marginLeft: 'auto', marginRight: 'auto' }}>
                <h3 style={{ textAlign: "center", margin: "20px" }}>Личный кабинет</h3>
                <p>Имя пользователя: {user?.userName}</p>
                <p>Роли пользователя: {user?.roles.map((role) => role + " ")}</p>
                <p>Идентификатор пользователя: {user?.id}</p>
                <br />
                <Button onClick={logout} variant="danger">Выйти</Button>
            </div>
        </div>
    );
}

export default Account;