import React, { useCallback } from "react";
import axios from 'axios';
import { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { Button, Input, Select } from "antd";
import { CloseOutlined, EditFilled, SaveFilled, ClearOutlined, FilePdfTwoTone } from '@ant-design/icons';
import { FormLabel, Form } from "react-bootstrap";

import { CertificateDto } from "../types/Certificate";
import { SizeInfo } from "../types/SizeInfo";
import { baseUrl } from "../BaseUrl";

function CertificateDetails() {
    const { id } = useParams()
    const [certificate, setCertificate] = useState<CertificateDto>();
    const [titleEditable, setTitleEditable] = useState(false);
    const [sizes, setSizes] = useState<SizeInfo[]>([]);

    const fetchData = useCallback(() => {
        const GetCertificateById = async () => {
            const response = await axios.get(baseUrl + '/api/Certificates/' + id, { withCredentials: true });
            setCertificate(response.data)
        };
        const GetSizes = async () => {
            const response = await axios.get(baseUrl + '/api/Certificates/GetSizes', { withCredentials: true });
            setSizes(response.data);
        }

        GetSizes();
        GetCertificateById();
    }, [id]);

    useEffect(() => {

        if (certificate?.photos === undefined || certificate?.photos.length === 0) {
            const timer = setTimeout(() => {
                console.log("fetch again...");
                fetchData();
            }, 2000);

            // Очищаем таймер при размонтировании компонента
            return () => clearTimeout(timer);
        }
    }, [certificate?.photos, id, fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function handleDelete(sertificateId: string, fileName: string) {
        const DelFileByName = async () => {
            await axios.delete(baseUrl + '/api/Certificates/' + sertificateId + '/RemoveFile/' + fileName, { withCredentials: true })
                .then(() => {
                    axios.get(baseUrl + '/api/Certificates/' + id, { withCredentials: true }).then((response) => {
                        setCertificate(response.data);
                    });
                });
        };
        DelFileByName();
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFiles = Array.from(event.target.files as FileList) as File[]

        submitNewFiles(newFiles);
    };

    const submitNewFiles = async (newFiles: File[]) => {
        if (certificate === undefined)
            return;

        const formData = new FormData();
        formData.append('name', certificate?.name);
        formData.append('sizeTypeId', certificate?.sizeInfo?.id === undefined ? '' : certificate?.sizeInfo?.id as string);
        if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
                formData.append('files', newFiles[i]);
            }
        }
        await axios.put(baseUrl + '/api/Certificates/Update/' + id, formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true
            })
            .then(() => {
                axios.get(baseUrl + '/api/Certificates/' + id, { withCredentials: true }).then((response) => {
                    setCertificate(response.data);
                });
            });
    }

    function handleSizeChanged(sizeId: string) {
        setCertificate({ ...certificate as CertificateDto, sizeInfo: sizes.find((size) => size.id === sizeId) })

        const formData = new FormData();
        formData.append('name', certificate?.name as string);
        formData.append('sizeTypeId', sizeId);

        axios.put(baseUrl + '/api/Certificates/Update/' + id, formData,
            { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true })

    }

    function handleTitleChangeClicked() {

        if (titleEditable) {
            const formData = new FormData();
            formData.append('name', certificate?.name as string);
            formData.append('sizeTypeId', certificate?.sizeInfo?.id === undefined ? '' : certificate?.sizeInfo?.id as string);

            axios.put(baseUrl + '/api/Certificates/Update/' + id, formData,
                { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true });
        }

        setTitleEditable(!titleEditable);

    }

    return certificate != null ? (
        <div className="page-container">
            <Helmet>
                <title>{"Сертификат " + certificate?.name}</title>
            </Helmet>

            <div style={{ margin: "10px" }} className="p-2 border rounded-4">
                <ListGroup as="ul" >
                    <ListGroup.Item>
                        <h4>
                            {titleEditable ?
                                <Input type="text" value={certificate?.name} onChange={(e) => setCertificate({ ...certificate, name: e.target.value })} />
                                : certificate?.name}
                            <Button type="link" onClick={handleTitleChangeClicked}>{titleEditable ? <SaveFilled /> : <EditFilled />}</Button>
                        </h4>
                    </ListGroup.Item>
                    <ListGroup.Item style={{ flexDirection: "row", display: "flex", justifyContent: "space-around" }}>
                        <div style={{ flexDirection: "row", display: "flex", minWidth: "50%" }}>
                            <FormLabel style={{ margin: "10px" }}>Тип</FormLabel>
                            <Select style={{ width: "100%", margin: "10px" }} placeholder="Не выбран" optionFilterProp="label" showSearch value={certificate?.sizeInfo?.id} onChange={handleSizeChanged}
                                options={sizes.map((size) => ({ value: size.id, label: size.name }))} />
                            <Button style={{ margin: "10px" }} icon={<ClearOutlined />} onClick={() => handleSizeChanged("")} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <div
                            className="d-flex justify-content-around align-items-center"
                            style={{ flexDirection: "column" }}
                        >
                            <div className="images-container">
                                {certificate.photos === undefined || certificate.photos.length === 0 ? <div>Нет приложений</div> :
                                    certificate.photos?.map((photo, index) => (
                                        <div key={index} className="image-wrapper">
                                            <Button onClick={() => handleDelete(certificate.id, photo.name)} danger icon={<CloseOutlined />} size="small" shape="circle" style={{ position: "absolute", right: "-10px", top: "-10px" }} />
                                            {photo.name.includes(".pdf") ?
                                                <Button type="link" icon={<FilePdfTwoTone />} onClick={() => window.open(photo.url as string, "_blank")} >{photo.name}</Button>
                                                :
                                                <a id="myLink" href={photo.url as string}>
                                                    <img src={photo.url as string} alt={photo.name} />
                                                </a>
                                            }
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <Form.Group className="mb-3" >
                            <FormLabel className="m-3">Добавить файл</FormLabel>
                            <Form.Control type="file" multiple onChange={handleFileChange} />
                        </Form.Group>
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </div>
    ) : <div />;
}

export default CertificateDetails;