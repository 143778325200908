import React, { FormEvent, useEffect, useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { FormLabel, ListGroup } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Checkbox } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import { baseUrl } from '../BaseUrl';

const CreateUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [userRoles, setUserRoles] = useState<Map<string, boolean>>(new Map<string, boolean>());

    const getRoles = async () => {
        const response = await axios.get(baseUrl + "/Role/GetRoles",
            { withCredentials: true }
        );
        setUserRoles(new Map(response.data.map((role: string) => [role, false])));
    };

    useEffect(() => {
        getRoles();
    }, []);

    const handleCreate = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const createResponse = await axios.post(baseUrl + '/UserManagement/CreateUserWithRoles', {
                userName: username,
                password: password,
                roles: Array.from(userRoles.entries()).filter((pair) => pair[1]).map((pair) => pair[0]),
            }, { withCredentials: true });

            if (createResponse.data.succeeded) {
                setMessage('Пользователь создан успешно!');
            } else {
                setMessage('Ошибка создания пользователя.');
            }
        } catch (error: any) {
            setMessage(error.response ? error.response.data : 'Ошибка сети');
        }
    };

    return (
        <div className='page-container'>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '400px', margin: 'auto' }}>
                <Form.Label style={{ fontSize: '24px', fontStyle: 'bold' }}>Создание пользователя</Form.Label>
                <Form onSubmit={handleCreate}>
                    <Form.Group style={{ marginTop: '10px' }}>
                        <Form.Control
                            type="text"
                            placeholder="Имя пользователя"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group style={{ marginTop: '10px' }}>
                        <Form.Control
                            type="password"
                            placeholder="Пароль"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <h6 style={{ margin: '10px' }}>Роли</h6>
                        <ListGroup>
                            <Container>
                                {Array.from(userRoles.keys()).map((role) => (
                                    <Row>
                                        <Col>
                                            <FormLabel style={{ verticalAlign: 'middle' }}>{role}</FormLabel>
                                        </Col>
                                        <Col>
                                            <Checkbox
                                                style={{ marginLeft: '10px', verticalAlign: 'middle' }}
                                                value={userRoles.get(role)}
                                                onChange={(e, checked) => setUserRoles(userRoles.set(role, checked))}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </Container>
                        </ListGroup>
                    </Form.Group>
                    {/* We should use Button from bootstrap because of type="submit" */}
                    <Button style={{ marginTop: '10px' }} type="submit">Создать</Button>
                </Form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default CreateUser;
