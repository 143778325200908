import React from "react";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Button } from "antd";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../BaseUrl";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

interface User {
    id: number
    userName: string
    roles: string[]
}

export default function UserManagement() {
    const fetchUsers = async () => {
        const response = await axios.get(baseUrl + "/UserManagement/GetUsers",
            { withCredentials: true }
        );
        setUsers(response.data)
    };

    const handleDelete = async (name: string) => {
        await axios.post(baseUrl + `/UserManagement/DeleteUser/` + name,
            { withCredentials: true }
        );
        fetchUsers();
    };

    const [currentUser, setCurrentUser] = React.useState<User>();

    const getUser = async () => {
        const response = await axios.get(baseUrl + "/UserManagement/LocalUser", { withCredentials: true });
        setCurrentUser(response.data);
        console.log(response.data);
    };

    const navigate = useNavigate();

    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, []);

    const [users, setUsers] = useState<User[]>();

    const columns: ColumnsType<User> = [
        {
            dataIndex: 'userName',
            title: 'Имя пользователя',
            width: "40%"
        },
        {
            dataIndex: 'roles',
            title: 'Роли пользователя',
            width: "60%",
            render: (roles: string[]) => roles.join(", ")
        },
        {
            dataIndex: 'delete',
            render: (_, record: User) =>
                record.userName === "admin" || record.userName === currentUser?.userName ? null :
                    <Button danger onClick={() => handleDelete(record.userName)}>Удалить</Button>
        }
    ];

    return (
        <div className='page-container'>
            <h3 style={{ textAlign: "center", margin: "20px" }}>Управление пользователями</h3>
            <div style={{ display: "flex", alignItems: "center", margin: "20px", justifyContent: "center" }}>
                <Box sx={{ height: "100%", width: '100%' }}>
                    <Table
                        dataSource={users}
                        columns={columns}
                    />
                    <Button onClick={() => navigate("/UserManagement/Create")} type="primary" style={{ marginTop: "20px" }}>
                        Создать пользователя
                    </Button>
                </Box>
            </div>
        </div>
    );
}